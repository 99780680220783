export default {
  "landingpageLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجعل الطعام عبر الإنترنت والبقالة وتوصيل الطرود والتسوق في الصيدليات سريعا وسهلا. احصل على توصيل البقالة واطلب الأطعمة المفضلة لديك من أفضل البائعين."])},
  "landingpageHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كل ما تحتاجه، يتم توصيله الآن"])},
  "landingpageSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلب الطعام عبر الإنترنت ، واحصل على توصيل البقالة ، وتسوق عبر الإنترنت مع أفضل متجر بقالة في منطقتك."])},
  "landingpageButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اكتشف البائعين المحليين الذين يقومون بالتوصيل إلى عتبة داركم"])},
  "landingpageSellerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كن بائعا"])},
  "landingpageSellerSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" سجل كبائع وافتح متجرا لبدء عملك اليوم."])},
  "landingpageSellerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل"])},
  "landingpageSellerFeat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سهل الطلب"])},
  "landingpageSellerFeat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسليم سريع"])},
  "landingpageSellerFeat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أفضل جودة"])},
  "landingpageSellerFeat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكثير من طرق الدفع"])},
  "landingpageRide": {
    "landingpageRiderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اركب معنا"])},
    "landingpageRiderSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل كمتسابق واكسب المال عن طريق الركوب معنا."])}
  },
  "downloadPage": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اجعل متجرك عبر الإنترنت أسهل مع تطبيق الهاتف المحمول الخاص بنا"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يجعل الطعام عبر الإنترنت والبقالة وتوصيل الطرود والتسوق في الصيدليات سريعا وسهلا. احصل على توصيل البقالة واطلب الأطعمة المفضلة لديك من أفضل البائعين."])}
  },
  "footer": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شركة"])},
    "sub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اتصل بنا"])},
    "sub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سياسة الخصوصية"])},
    "sub3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الشروط والأحكام"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تعامل معنا"])},
    "sub4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل تحتاج إلى ركاب أو سائقين؟"])},
    "sub5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تريد البيع على"])},
    "header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حساب"])},
    "sub6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إدارة الحساب"])},
    "sub7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اوامر"])},
    "header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمات"])},
    "sub8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث عن متجر"])},
    "header5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["فئات"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حقوق النشر"])},
    "copyright2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جميع الحقوق محفوظة"])}
  },
  "cookieText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نحن نستخدم ملفات تعريف الارتباط لتحسين تجربتك على موقعنا. من خلال تصفح هذا الموقع ، فإنك توافق على استخدامنا لملفات تعريف الارتباط."])},
  "cookieButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقبل"])},
  "nav": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسجيل الدخول"])}
  },
  "pages": {
    "bestselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأكثر مبيعا"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أحدث"])},
    "featured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متميز"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكلم"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البحث عن المنتجات"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تكلم"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتائج البحث"])},
    "searchResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نتائج البحث عن"])},
    "searchResult3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على نتائج"])},
    "searchResult4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لم يتم العثور على نتائج ل"])},
    "seeall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شاهد الكل"])},
    "nearbyvendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البائعون القريبون"])},
    "foryou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لك"])},
    "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البائعين"])},
    "vendorSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ابحث عن أفضل البائعين في منطقتك"])},
    "vendorSubHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لدينا جميع البائعين المفضلين لديك هنا"])},
    "newArrivals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوافدون الجدد"])},
    "newArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["وصول جديد"])},
    "flashSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مبيعات فلاش"])},
    "flashSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيع فلاش"])},
    "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الكتب"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كتاب"])},
    "todaysPicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختيارات اليوم"])},
    "noVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يوجد بائعون"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر ملف"])},
    "popularServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخدمات الشعبية"])},
    "topRated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأعلى تقييما"])}
  },
  "Instock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["متوفر في المخزن"])},
  "Outofstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["غير متوفر في المخزن"])},
  "BestSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الأكثر مبيعا"])},
  "hot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حار"])},
  "product": {
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كم"])},
    "addtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["جمع"])},
    "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موصى به لك"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استمر"])},
    "optionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خيارات"])},
    "optionSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد خيارات لإضافتها إلى المنتج"])}
  },
  "cart": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلة التسوق الخاصة بك"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثمن"])},
    "totalProductPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي سعر المنتج"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مجموع"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الخروج"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلة التسوق الخاصة بك فارغة"])},
    "empty2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سلة التسوق الخاصة بك فارغة. ابدأ التسوق الآن."])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اضافات"])},
    "noExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا توجد إضافات متاحة"])},
    "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ملخص الطلب"])},
    "orderSummary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["لا يشمل هذا المجموع الفرعي تكلفة التسليم أو الاستلام"])},
    "totalItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إجمالي البند"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المجموع الفرعي"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تسليم"])},
    "Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خصم"])},
    "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المبلغ الإجمالي"])},
    "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك حساب؟"])},
    "useADiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["استخدم رمز الخصم"])},
    "getDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["احصل على خصم"])},
    "shoppingWithSSLEncryption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسوق باستخدام تشفير SSL"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ينضم"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أو"])},
    "forAsmootherCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["للحصول على دفع أكثر سلاسة"])},
    "continueToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المتابعة إلى الدفع"])}
  },
  "login": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة السر"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل نسيت كلمة المرور؟"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["البريد الإلكتروني أو كلمة المرور غير صحيحة"])},
    "dontHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك حساب؟"])},
    "alreadyHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هل لديك حساب بالفعل؟"])},
    "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["التسجيل"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سجل"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اسم"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الهاتف"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مدينة"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حالة"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الرمز البريدي"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بلد"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور"])}
  },
  "GroceryHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["هنا اطلب البقالة المفضلة لديك من فئات مختلفة"])},
  "pickupOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلب الاستلام"])},
  "pharmacyIndicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى الإشارة إلى ما إذا كنت ستتسلم الطلب من البائع"])},
  "selectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يرجى اختيار عنوان / موقع التسليم"])},
  "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طرق الدفع"])},
  "placeOrderRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقديم طلب طلب"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان التسليم"])},
  "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إضافة عنوان"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حرر"])},
  "parcel": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلب استلام الطرود اليوم"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال الطرود أو الطرود إلى الأصدقاء والعائلات."])},
    "trackPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تتبع الحزمة الخاصة بك"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اطلب الان"])},
    "recentOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الطلبات الأخيرة"])},
    "sendPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["إرسال الطرود"])},
    "subHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["خدمة البريد السريع المحلية عند الطلب"])},
    "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مسح الكل"])},
    "packageTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أنواع الحزم"])},
    "selectPackageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["اختر نوع الحزمة"])},
    "courierVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بائعو البريد السريع"])},
    "selectCourierVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حدد بائع البريد السريع"])},
    "deliveryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات التوصيل"])},
    "pickupDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["أضف البيك اب والوجهة هنا"])},
    "pickupLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع الاستلام"])},
    "dropoffLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["موقع التسليم"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تاريخ"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["الوقت"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مقبل"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["سابق"])},
    "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المستلمين"])},
    "recipientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات المستلم"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلومات الاتصال"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تلاحظ"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["من خلال التأكيد على أنني أوافق على أن هذا الطلب لا يحتوي على عناصر غير قانونية / معاد ضبطها ، إذا تم العثور على عناصر غير قانونية / مقيدة بواسطة "])},
    "disclaimer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["شريك ، يمكنهم الإبلاغ عن ذلك إلى سلطات إنفاذ القانون. تطبق الشروط والأحكام."])},
    "packageParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["معلمات الحزمة"])},
    "subHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["املأ جميع معلمات الحزمة المطلوبة هنا"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ثقل"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طول"])},
    "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عرض"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ارتفاع"])},
    "deliveryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تفاصيل التوصيل"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بيك اب"])},
    "dropoffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عمليات التوصيل"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["دفع"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["بون"])},
    "deliveryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم التوصيل"])},
    "packageSizeCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم حجم العبوة"])}
  },
  "checkout": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كيف ترغب في استلام طلبك؟"])},
    "yourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلبك"])},
    "orderSummaryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["يشمل هذا المجموع الفرعي تكلفة التسليم أو الاستلام"])},
    "deliveryFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["رسوم التوصيل"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ضريبة"])},
    "driverTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["نصيحة السائق"])},
    "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تقديم الطلب"])}
  },
  "orders": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["طلباتي"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك أي طلبات"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["حاصل الضرب"])}
  },
  "profile": {
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["المعلومات الشخصية"])},
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["مرحبًا"])},
    "passwordChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تغيير كلمة المرور"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة المرور القديمة"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["كلمة السر الجديدة"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تأكيد كلمة المرور"])},
    "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث كلمة المرور"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["عنوان"])},
    "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["تحديث الملف الشخصي"])},
    "noAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ليس لديك عنوان"])}
  }
}