export default {
  "landingpageLeft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rend la nourriture en ligne, l’épicerie, la livraison de colis et les achats en pharmacie rapides et faciles. Faites-vous livrer vos courses et commandez vos aliments préférés auprès des meilleurs vendeurs."])},
  "landingpageHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tout ce dont vous avez besoin, livré maintenant"])},
  "landingpageSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandez de la nourriture en ligne, faites-vous livrer vos courses et magasinez en ligne avec la meilleure épicerie de votre région."])},
  "landingpageButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Découvrez les fournisseurs locaux qui livrent à votre porte"])},
  "landingpageSellerHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Devenir vendeur"])},
  "landingpageSellerSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Inscrivez-vous en tant que vendeur et ouvrez une boutique pour démarrer votre entreprise dès aujourd’hui."])},
  "landingpageSellerButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre"])},
  "landingpageSellerFeat1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["facile à commander"])},
  "landingpageSellerFeat2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["livraison rapide"])},
  "landingpageSellerFeat3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleure qualité"])},
  "landingpageSellerFeat4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beaucoup de méthodes de paiement"])},
  "landingpageRide": {
    "landingpageRiderHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Roulez avec nous"])},
    "landingpageRiderSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inscrivez-vous en tant que cavalier et gagnez de l’argent en roulant avec nous."])}
  },
  "downloadPage": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Facilitez votre boutique en ligne avec notre application mobile"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rend la nourriture en ligne, l’épicerie, la livraison de colis et les achats en pharmacie rapides et faciles. Faites-vous livrer vos courses et commandez vos aliments préférés auprès des meilleurs vendeurs."])}
  },
  "footer": {
    "header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compagnie"])},
    "sub1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contactez-nous"])},
    "sub2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politique de confidentialité"])},
    "sub3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Termes & Conditions"])},
    "header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Faites affaire avec nous"])},
    "sub4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Besoin de riders ou de drivers?"])},
    "sub5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous voulez vendre sur"])},
    "header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Compte"])},
    "sub6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gérer le compte"])},
    "sub7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ordres"])},
    "header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services"])},
    "sub8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouver un magasin"])},
    "header5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Catégories"])},
    "copyright": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Copyright"])},
    "copyright2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tous droits réservés"])}
  },
  "cookieText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous utilisons des cookies pour améliorer votre expérience sur notre site Web. En naviguant sur ce site, vous acceptez notre utilisation des cookies."])},
  "cookieButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accepter"])},
  "nav": {
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Connectez-vous"])}
  },
  "pages": {
    "bestselling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meilleures ventes"])},
    "newest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau"])},
    "featured": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vedettes"])},
    "search": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "searchPlaceholder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher des produits"])},
    "searchButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechercher"])},
    "searchResult": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de la recherche"])},
    "searchResult2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résultats de la recherche pour"])},
    "searchResult3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé"])},
    "searchResult4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun résultat trouvé pour"])},
    "seeall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voir tout"])},
    "nearbyvendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseurs à proximité"])},
    "foryou": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pour vous"])},
    "vendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseurs"])},
    "vendorSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Trouvez les meilleurs fournisseurs dans votre région"])},
    "vendorSubHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nous avons tous vos fournisseurs préférés ici"])},
    "newArrivals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveaux arrivants"])},
    "newArrival": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["New Arrival"])},
    "flashSales": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ventes Flash"])},
    "flashSale": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vente flash"])},
    "books": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livres"])},
    "book": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livre"])},
    "todaysPicks": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les choix d’aujourd’hui"])},
    "noVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun fournisseur"])},
    "chooseFile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Choisissez Fichier"])},
    "popularServices": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Services populaires"])},
    "topRated": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Les mieux notés"])}
  },
  "Instock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En stock"])},
  "Outofstock": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rupture de stock"])},
  "BestSeller": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Best-seller"])},
  "hot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Chaud"])},
  "product": {
    "quantity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Quantité"])},
    "addtocart": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter"])},
    "recommended": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Recommandé pour vous"])},
    "continue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer"])},
    "optionHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Options"])},
    "optionSubHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez les options pour les ajouter au produit"])}
  },
  "cart": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier"])},
    "price": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix"])},
    "totalProductPrice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prix total du produit"])},
    "total": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total"])},
    "checkout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Caisse"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier est vide"])},
    "empty2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre panier est vide. Commencez à magasiner maintenant."])},
    "extras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extras"])},
    "noExtras": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aucun extra disponible"])},
    "orderSummary": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Résumé de la commande"])},
    "orderSummary2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce sous-total n’inclut pas les frais de livraison ou de ramassage"])},
    "totalItems": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Total de l’objet"])},
    "subtotal": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sous-total"])},
    "delivery": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Livraison"])},
    "Discount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rabais"])},
    "totalAmount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Montant total"])},
    "haveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez un compte ?"])},
    "useADiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utilisez un code de réduction"])},
    "getDiscount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Obtenez une réduction"])},
    "shoppingWithSSLEncryption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Shopping avec cryptage SSL"])},
    "join": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unir"])},
    "or": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ou"])},
    "forAsmootherCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pour un paiement plus fluide"])},
    "continueToCheckout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Continuer à la caisse"])}
  },
  "login": {
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Messagerie électronique"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mot de passe oublié?"])},
    "error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L’adresse e-mail ou le mot de passe est incorrect"])},
    "dontHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n’avez pas de compte ?"])},
    "alreadyHaveAnAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous avez déjà un compte ?"])},
    "SignUp": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["S’enregistrer"])},
    "register": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Registre"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nom"])},
    "phone": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Téléphone"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ville"])},
    "state": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["État"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fermeture éclair"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pays"])},
    "password2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])}
  },
  "GroceryHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ici commandez votre épicerie préférée de différentes catégories"])},
  "pickupOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commande de ramassage"])},
  "pharmacyIndicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez indiquer si vous allez ramasser la commande du fournisseur"])},
  "selectAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Veuillez sélectionner l’adresse/l’emplacement de livraison"])},
  "paymentMethods": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Modes de paiement"])},
  "placeOrderRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer une demande de commande"])},
  "deliveryAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse de livraison"])},
  "addAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter une adresse"])},
  "edit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Éditer"])},
  "parcel": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandez pour un ramassage de colis aujourd’hui"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyez des colis ou des colis à vos amis et à votre famille."])},
    "trackPackage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suivez votre colis"])},
    "orderNow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandez maintenant"])},
    "recentOrders": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Commandes récentes"])},
    "sendPackages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Envoyer des colis"])},
    "subHeader2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre service de messagerie local à la demande"])},
    "clearAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Effacer tout"])},
    "packageTypes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Types de packages"])},
    "selectPackageType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionner un type de package"])},
    "courierVendors": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fournisseurs de messagerie"])},
    "selectCourierVendor": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sélectionnez un fournisseur de services de messagerie"])},
    "deliveryInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations de livraison"])},
    "pickupDestination": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ajouter le ramassage et la destination ici"])},
    "pickupLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de prise en charge"])},
    "dropoffLocation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lieu de dépôt"])},
    "date": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Date"])},
    "time": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heure"])},
    "next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prochain"])},
    "previous": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Précédent"])},
    "recipients": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Destinataires"])},
    "recipientInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informations sur le destinataire"])},
    "contactInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Coordonnées"])},
    "notes": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Notes"])},
    "disclaimer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["En confirmant que j’accepte que cette commande ne contienne pas d’articles illégaux / resticés, si des articles illégaux / restreints sont trouvés par "])},
    "disclaimer2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Partenaire, ils peuvent le signaler aux autorités chargées de l’application de la loi. Des conditions générales s’appliquent."])},
    "packageParameters": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paramètres du package"])},
    "subHeader3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Remplissez tous les paramètres de package requis ici"])},
    "weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poids"])},
    "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Longueur"])},
    "width": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Largeur"])},
    "height": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauteur"])},
    "deliveryDetails": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Détails de livraison"])},
    "pickup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick-up"])},
    "dropoffs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dépôts"])},
    "payment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Paiement"])},
    "distance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Distance"])},
    "deliveryCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de livraison"])},
    "packageSizeCharges": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de taille d’emballage"])}
  },
  "checkout": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Comment souhaitez-vous recevoir votre commande ?"])},
    "yourOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Votre commande"])},
    "orderSummaryText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ce sous-total comprend les frais de livraison ou de ramassage"])},
    "deliveryFee": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frais de livraison"])},
    "tax": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxe"])},
    "driverTip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Conseil du pilote"])},
    "placeOrder": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passer commande"])}
  },
  "orders": {
    "header": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mes commandes"])},
    "subHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n’avez pas de commandes"])},
    "product": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Produit"])}
  },
  "profile": {
    "personalInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Renseignements personnels"])},
    "Hello": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bonjour"])},
    "passwordChange": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Changement de mot de passe"])},
    "oldPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ancien mot de passe"])},
    "newPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nouveau mot de passe"])},
    "confirmPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Confirmer le mot de passe"])},
    "updatePassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le mot de passe"])},
    "address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "updateProfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mettre à jour le profil"])},
    "noAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vous n’avez pas d’adresse"])}
  }
}